import React from "react"
import styled from "styled-components"

const IndexHeroTitle = styled.h1`
    display: inline-block;
    width: 100%;
    font-size: 52px;
    letter-spacing: 15px;
    font-weight: 700;
    text-align: center;
    color: white;
    margin: 40px 0px 40px 0px;
`

const OtherHeroTitle = styled.h1`
    position: absolute;
    top: 260px;
    left: 0px;
    display: inline-block;
    width: 100%;
    font-size: 52px;
    letter-spacing: 15px;
    font-weight: 700;
    text-align: center;
    color: white;
`

const HeroTitle = (props) => {

    if (props.label === "UPDATE THE INDUSTRY") {
        return <IndexHeroTitle>{props.label}</IndexHeroTitle>
    } else {
        return <OtherHeroTitle>{props.label}</OtherHeroTitle>
    }
}

export default HeroTitle