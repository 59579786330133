import React from "react"
import { GoToTopButton, LogoButton, LinkButton, SNSIconButton } from "../atoms/Button"
import Pages from "../../../data/PagesData"
import styled from "styled-components"
import { FaceBookIconSVG, TwitterIconSVG, NoteIconSVG } from "../../Images/svg"

const FooterContentsOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(436px - 102px);
    background: #393939;
`

const FooterContentsWrapper = styled.div`
    position: relative;
    bottom: 30px;
    display: flex;
    width: 81%;
    height: 50%;
`

const LogoButtonWrapper = styled.div`
    display: inline-block;
    height: 100%;
`

const LinkButtonsWrapper = styled.div`
    position: relative;
    bottom: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin: 6px 0px 0px 50px;
`

const SubContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    height: 84%;
    margin-left: auto;
`

const SNSButtonWrapper = styled.div`
    display: flex;
    width: 104px;
    height: 24px;
    justify-content: space-between;
    margin-bottom: 10px;
`

const Copyright = styled.p`
    color: white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.5px;
`

const FooterContents = () => {

    return (
        <FooterContentsOuter>
            <GoToTopButton />
            <FooterContentsWrapper>
                <LogoButtonWrapper>
                    <LogoButton color="white" />
                </LogoButtonWrapper>
                <LinkButtonsWrapper>
                    {Pages.slice(1, 5).map((p, idx) => (
                        <LinkButton key={idx} label={p.name} linkto={p.linkto} color="white" />
                    ))}
                </LinkButtonsWrapper>
                <LinkButtonsWrapper>
                    {Pages.slice(5, 9).map((p, idx) => (
                        <LinkButton key={idx} label={p.name} linkto={p.linkto} color="white" />
                    ))}
                </LinkButtonsWrapper>
                <SubContentsWrapper>
                    <SNSButtonWrapper>
                        <SNSIconButton linkto="https://www.facebook.com/QUANDOInc/">
                            <FaceBookIconSVG />
                        </SNSIconButton>
                        <SNSIconButton linkto="https://twitter.com/SynQRemote">
                            <TwitterIconSVG />
                        </SNSIconButton>
                        <SNSIconButton linkto="https://note.com/synq">
                            <NoteIconSVG />
                        </SNSIconButton>
                    </SNSButtonWrapper>
                    <Copyright>© 2020 QUANDO Inc. All Rights Reserved.</Copyright>
                </SubContentsWrapper>
            </FooterContentsWrapper>
        </FooterContentsOuter>
    )
}

export default FooterContents