import React from "react"
import HeroTitle from "../atoms/HeroTitle"
import { IndexHeroScroll } from "../atoms/Decoration"
import styled from "styled-components"

const IndexHeroContentsOuter = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
`

const CentralContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 54%;
`

const BottomContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 46%;
`

const IndexHeroContents = (props) => {


    return (
        <IndexHeroContentsOuter>
            <CentralContentsWrapper>
                <HeroTitle label={props.label} />
            </CentralContentsWrapper>
            <BottomContentsWrapper>
                <IndexHeroScroll />
            </BottomContentsWrapper>
        </IndexHeroContentsOuter>
    )
}

export default IndexHeroContents