import React from "react"
import Img from "gatsby-image"
import IndexBackgroundMovieSrc from "../../../../static/PC_720_min.mp4"
import styled from "styled-components"
import FluidImages from "../../Images/FluidImages"

const IndexBackgroundMovieOuter = styled.div`
    display: inline-block;
    width: 100%;
    height: 92.5%;
    object-fit: cover;
`

const IndexBackgroundMovie = styled.video`
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const IndexBackgroundMovieEffect = styled.div`
    position: absolute;
    display: inline-block;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 92.5vh;
    opacity: 0.4;
    background-size: 4px 4px;
    background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, #000), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, #000), color-stop(0.75, #000), color-stop(0.75, transparent), to(transparent));
`

const HeroBackground = (props) => {

    if (props.page === "INDEX") {
        return (
            <IndexBackgroundMovieOuter>
                <IndexBackgroundMovie src={IndexBackgroundMovieSrc} muted autoPlay loop />
                <IndexBackgroundMovieEffect />
            </IndexBackgroundMovieOuter>
        )
    } else {
        return (
            <Img
                fluid={FluidImages(props.page)}
                style={{ display: "inline-block", width: "100%", height: "600px", objectFit: "cover" }}
            />
        )
    }
}

export default HeroBackground