import React from "react"
import FooterContents from "../molecules/FooterContents"
import styled from "styled-components"

const FooterOuter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 436px;
`

const FooterDecoration = styled.div`
    display: inline-block;
    width: 100%;
    height: 102px;
    background: black;
`

const Footer = () => {

    return (
        <FooterOuter>
            <FooterDecoration />
            <FooterContents />
        </FooterOuter>
    )

}

export default Footer