import { useStaticQuery, graphql } from "gatsby"

const FluidImages = request => {
    const data = useStaticQuery(graphql`
        query {
            ProductBg: file(relativePath: { eq: "PC/top-product-bg.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 536) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            CollaboBg: file(relativePath: { eq: "PC/top-collabo-bg.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 536) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            RecruitBg: file(relativePath: { eq: "PC/top-recruit-bg.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1117, maxHeight: 1211) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            NEWS: file(relativePath: { eq: "PC/news-hero-bg.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            VISION: file(relativePath: { eq: "PC/vision-hero-bg.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            COMPANY: file(relativePath: { eq: "PC/company-hero-bg.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            SERVICE: file(relativePath: { eq: "PC/service-hero-bg.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            TECHNOLOGY: file(relativePath: { eq: "PC/tech-hero-bg.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            RECRUIT: file(relativePath: { eq: "PC/recruit-hero-bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            CONTACT: file(relativePath: { eq: "PC/contact-hero-bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            RecruitPhotoMiddle: file(
                relativePath: { eq: "PC/recruit-photo-middle.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return data[request].childImageSharp.fluid
}

export default FluidImages
