import React from "react"


const Spacer = (props) => {

    const SpacerStyles = {
        position: "relative",
        display: "inline-block",
        width: "100%",
        height: props.height
    }

    return (
        <div style={SpacerStyles}></div>
    )
}

export default Spacer