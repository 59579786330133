import React from "react"
import HeroBackground from "../atoms/HeroBackground"
import IndexHeroContents from "../molecules/IndexHeroContents"
import HeroTitle from "../atoms/HeroTitle"
import styled from "styled-components"

const IndexHeroOuter = styled.div`
    display: inline-block;
    width: 100%;
    height: 100vh;
    background: black;
`

const OtherHeroOuter = styled.div`
    display: inline-block;
    width: 100%;
    height: 600px;
    background: black;
`

const Hero = (props) => {

    if (props.page === "INDEX") {
        return (
            <IndexHeroOuter>
                <HeroBackground page={props.page} />
                <IndexHeroContents label="UPDATE THE INDUSTRY" />
            </IndexHeroOuter>
        )
    } else {
        return (
            <OtherHeroOuter>
                <HeroBackground page={props.page} />
                <HeroTitle label={props.page} />
            </OtherHeroOuter>
        )
    }

}

export default Hero