import React, { useState } from "react"
import Spacer from "../atoms/Spacer"
import HeaderInner from "../molecules/HeaderInner"
import styled from "styled-components"

const HeaderOuter = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background: white;
    border-bottom: 1px solid #f1f1f1;
`

const TopHeaderOuter = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 96px;
`

const Header = (props) => {

    const [viewingYoffset, setYoffset] = useState(0)
    const onServerSideBuild = typeof window === `undefined`

    setInterval(function() {
        const latestYoffset = (onServerSideBuild) ? 0 : window.pageYOffset
        if (viewingYoffset !== latestYoffset) {
            setYoffset(window.pageYOffset)
        }
    }, 100)

    const indexHeader = props.page === "INDEX"
    const indexCondition = (indexHeader && !onServerSideBuild) ? viewingYoffset > window.innerHeight : false
    const otherCondition = (!indexHeader && !onServerSideBuild) ? viewingYoffset > 600 : false
    const condition = (indexHeader) ? indexCondition : otherCondition

    if (condition) {
        return (
            <HeaderOuter>
                <HeaderInner color="black" />
            </HeaderOuter>
        )
    } else {
        return (
            <TopHeaderOuter>
                <Spacer height="57px" />
                <HeaderInner color="white" />
            </TopHeaderOuter>
        )
    }
}

export default Header